body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image: url('./assets/MOUNTAIN-PHOTOGRAPHY-HALF-DOME-LAST-LIGHT-683x1024.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 50px;
}

nav ul {
  list-style-type: none;
  display: flex;
}

nav ul li {
  margin-left: 1rem;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

.hero {
  text-align: center;
  padding: 4rem 0;
  color: white;
}

.banner {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.about {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  margin: 2rem 0;
}

footer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: auto;
}

.contact {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  margin: 2rem 0;
}

.contact form {
  display: flex;
  flex-direction: column;
}

.contact form div {
  margin-bottom: 1rem;
}

.contact form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.contact form input,
.contact form textarea {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.contact form button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.contact form button:hover {
  background-color: #555;
}

.privacy-policy {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  margin: 2rem 0;
}

.privacy-policy h2 {
  color: #333;
  margin-bottom: 1rem;
}

.privacy-policy p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

footer p {
  margin: 0.5rem 0;
}